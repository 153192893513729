import React from 'react';
import PropTypes from 'prop-types';

import imagePropType from '../prop-types/image';

class InlineImages extends React.Component {
    render() {
        const { config, content } = this.props.block;

        const classNames = config.className
            ? `${config.className} InlineImages`
            : 'InlineImages';

        return (
            <section className={classNames}>
                <div className="InlineImages-container clearfix">
                    {content.map((image, key) => {
                        return (
                            <div
                                key={key}
                                className="InlineImage col-xs-4 col-sm-4 col-md-2">
                                <img src={image.src} alt={image.alt} />
                            </div>
                        );
                    })}
                </div>
            </section>
        );
    }
}

InlineImages.propTypes = {
    block: PropTypes.shape({
        content: PropTypes.arrayOf(imagePropType)
    })
};
export default InlineImages;
